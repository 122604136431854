<template lang="pug">
  v-tabs(color="primary" v-model="activeTab" background-color="transparent" height="40px").app-tabs
    v-tab(v-for="tab in tabs" :key="tab.name" @click="switchTab(tab.value)")
      div {{ tab.label }}
</template>

<script>
import { SLOTS_UPDATES_LIST_TABS } from '../core/cbr-conts'

export default {
  name: 'SlotUpdatesTabs',

  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: SLOTS_UPDATES_LIST_TABS.TOP,
          label: 'TOP',
          value: SLOTS_UPDATES_LIST_TABS.TOP,
        },
        {
          name: SLOTS_UPDATES_LIST_TABS.MIJN,
          label: 'MIJN',
          value: SLOTS_UPDATES_LIST_TABS.MIJN
        }
      ]
    },
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.query.tab === value) return

      await this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
          page: 1
        }
      })
      this.$emit('change')
    },

    setTabByQuery() {
      let name = this.$route.query.tab
      let tabIndex = this.tabs.findIndex(tab => tab.value === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
